import { forwardRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import fm from 'format-message';
import overlay from '@activebrands/core-web/libs/overlay';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import { inServer } from '@activebrands/core-web/utils/constants';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

/**
 *
 * @param {node} Component - The user will have to be signed in to access this component
 * @param {string} options.redirectUri - Once the user has signed in they will be redirected to this url.
 * @param {string} options.replace - Used to pass state data to the linked page.
 */

const withAuthRequired = Component => {
    const [css] = useStyletron();
    const AuthRequired = forwardRef((props, ref) => {
        const [isLoading, isAuthenticated] = useSelector(
            s => [s.auth.isLoading, s.auth.isAuthenticated],
            (p, n) => p[0] === n[0] && p[1] === n[1]
        );

        if (inServer) {
            return (
                <Helmet>
                    <meta content="noindex" name="robots" />
                </Helmet>
            );
        }

        useEffect(() => {
            if (!isLoading && isAuthenticated === false) {
                overlay.open('unauthenticated');
            }
        }, [isLoading, isAuthenticated]);

        return isAuthenticated ? (
            <Component ref={ref} {...props} />
        ) : (
            <div
                className={css({
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: ['8px', null, '18px'],
                })}
            >
                <Heading $style={{ marginBottom: '12px' }} fontKeys={['Primary/24_120_-2', null, 'Primary/32_110_-2']}>
                    {fm('Become a member')}
                </Heading>
                <Paragraph fontKeys="Secondary/16_130">
                    {fm('This page is locked, please sign in or create an account.')}
                </Paragraph>
            </div>
        );
    });
    AuthRequired.displayName = `withAuthRequired(${Component.displayName || Component.name})`;

    return AuthRequired;
};

export default withAuthRequired;
