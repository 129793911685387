import PropTypes from 'prop-types';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { getHeaderTheme } from 'components/Header/getHeaderTheme';
import withAuthRequired from 'components/auth/withAuthRequired';
import MyAccountNavigation from 'templates/account/MyAccountNavigation';

const GridMainLayout = styled('div', {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    padding: ['8px', null, '0 18px 18px 18px'],
});

const Wrapper = styled('div', {});

const AccountLayout = ({ children, page = {} }) => {
    const [css] = useStyletron();
    const theme = getHeaderTheme({ heroTextColor: page.data?.content });
    const alternateHrefs = page.data?.hrefs || [];

    const AccountAuthWrapper = withAuthRequired(Wrapper);

    return (
        <div>
            <Header alternateHrefs={alternateHrefs} theme={theme} />
            <div className={css({ minHeight: '100vh' })}>
                <AccountAuthWrapper>
                    <div
                        className={css({
                            display: 'grid',
                            gridTemplateColumns: 'repeat(12, 1fr)',
                            gap: '12px',
                            position: 'relative',
                        })}
                    >
                        <MyAccountNavigation />
                        <main
                            className={css({
                                flex: '1 0 auto',
                                gridColumn: ['1 / span 12', null, '3 / span 10'],
                            })}
                        >
                            <GridMainLayout>{children}</GridMainLayout>
                        </main>
                    </div>
                </AccountAuthWrapper>
            </div>
            <Footer alternateHrefs={alternateHrefs} />
        </div>
    );
};

AccountLayout.propTypes = {
    children: PropTypes.node,
    page: PropTypes.object.isRequired,
};

export default AccountLayout;
